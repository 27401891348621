<template>
    <div class="result">
        <comnavBar :Title="'做题结果'" :backType="'1'" @onClickLeft="onClickLeft"></comnavBar>
        <!-- 主体内容 -->
        <div class="result_content">
            <div class="result_title">{{testInfo.title}}</div>
            <div class="result_mask">
                <div :class="testInfo.isPass==='1'?'result_img result_img_success':'result_img result_img_fail'">
                    <!-- <div :class="testInfo.isPass==='1'?'result_score_success':'result_score_fail'">{{testInfo.score}}</div> -->
                    <van-circle
                            class="correct"
                            v-model:current-rate="currentRate"
                            :rate="testInfo.accuracy"
                            :stroke-width="60"
                            layer-color="#ebedf0"
                            :text="text"
                    />
                </div>
                <div class="correct_text">正确率=做对试题÷已做试题</div>
                <div class="result_threeDiv">
                    <div>
                        <div>{{testInfo.dtys}}</div>
                        <div>答题时间</div>
                    </div>
                    <div>
                        <div>{{testInfo.score}}/{{testInfo.totalScore}}</div>
                        <div>得分/总分</div>
                    </div>
                    <div>
                        <div>{{testInfo.totalTest}}</div>
                        <div>总题数</div>
                    </div>
                    <div>
                        <div>{{testInfo.correct}}</div>
                        <div>答对题目</div>
                    </div>
                </div>
            </div>
            <!-- 试题 -->
            <div class="result_test">
                <van-cell center title="答题卡">
                    <template #extra>
                        <div class="tuli">
                            <span>未做</span>
                            <van-tag color="#969799" plain></van-tag>
                            <span>做对</span>
                            <van-tag type="success"></van-tag>
                            <span>做错</span>
                            <van-tag type="danger"></van-tag>
                            <!-- <span>不全对</span><van-tag type="warning"></van-tag> -->
                        </div>
                    </template>
                </van-cell>
                <!-- 答题卡 -->
                <div
                        :class="[item.isFail==null?'result_test_none':'',item.isFail=='1'?'result_test_success':'',item.isFail=='2'?'result_test_fail':'',item.isFail=='3'?'result_test_part':'']"
                        v-for="(item,index) in testInfo.testList"
                        :key="index"
                        @click="updateCurrentTestFun(index)"
                >{{index+1}}
                </div>
            </div>
            <div @touchmove.stop>
                <van-popup class="showTest" v-model:show="showTest" position="bottom" closeable close-icon="close" :style="{ height: '100%',width:'100%' }">
                    <van-cell center title="试题详情" style="position: fixed;top: 0;text-align: center;z-index: 9999"></van-cell>
                    <!-- 试题 -->
                    <div class="result_testContent">
                        <!-- 题目 -->
                        <!-- <div class="result_test_tm">{{currentTest.num}}.{{currentTest.title}}</div> -->
                        
                        <van-cell center class="comRadio_top">
                            <template #title>
                                <span v-if="currentTest.type == '2'">单选题</span>
                                <span v-if="currentTest.type == '3'">多选题</span>
                                <span v-if="currentTest.type == '6'">配伍选择题</span>
                                <span v-if="currentTest.type == '1'">判断题</span>
                                <span v-if="currentTest.type == '4'">简答题</span>
                                <span v-if="currentTest.type == '5'">综合分析题</span>
                            </template>
                        </van-cell>
                        <!-- 选项 -->
                        <div class="result_test_xx">
                            <!-- 单选题 -->
                            <comRadio :type="'0'" v-if="currentTest.type == '2'" :obj="currentTest.type == '2' ? currentTest : ''" :answerStatus="true"></comRadio>
                            <!-- 多选题 -->
                            <comCheckbox :type="'0'" v-if="currentTest.type == '3'"
                                        :obj="currentTest.type == '3' ? currentTest : ''" :answerStatus="true">
                            </comCheckbox>
                            <!-- 配伍选择题-单选 -->
                            <comDataRadio :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='6'"
                                        :obj="currentTest.type == '6' ? currentTest : ''" :answerStatus="true">
                            </comDataRadio>
                            <!-- 配伍选择题-多选 -->
                            <comDataCheckbox :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='6'"
                                            :obj="currentTest.type == '6' ? currentTest : ''" :answerStatus="true">
                            </comDataCheckbox>
                            <!-- 综合分析题-单选 -->
                            <comDataRadio :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.type=='5'"
                                        :obj="currentTest.type == '5' ? currentTest : ''" :answerStatus="true">
                            </comDataRadio>
                            <!-- 综合分析题-多选 -->
                            <comDataCheckbox :type="'0'" :typeTest="currentTest.typeTest" v-if="currentTest.typ==='5'"
                                            :obj="currentTest.type == '5' ? currentTest : ''" :answerStatus="true">
                            </comDataCheckbox>
                            <!-- 判断题 -->
                            <comJudge :type="'0'" v-if="currentTest.type == '1'"
                                    :obj="currentTest.type == '1' ? currentTest : ''" :answerStatus="true">
                            </comJudge>
                            <!-- 简答题 -->
                            <comshortAnswer :type="'3'" v-if="currentTest.type == '4'"
                                            :obj="currentTest.type == '4' ? currentTest : ''" :answerStatus="true">
                            </comshortAnswer>
                        </div>
                    </div>
                    <!-- 答案 -->
                    <!-- <div class="result_testAnswer"> -->
                    <!-- 题目 -->
                    <!-- <div class="result_answer">
                    正确答案：
                    <span style="color:#1EB91D;font-weight:bold">{{currentTest.rightAnswer}}</span>
            </div> -->
                    <!-- 选项 -->
                    <!-- <div class="result_jx">
                    <div>解析</div>
                    <span style="color:#6B6B6B;margin-left:3vw">{{currentTest.jx}}</span>
            </div>
            </div> -->
                    <!--        <div class="comRadio_answer">-->
                    <!--          <div class="answer_box">-->
                    <!--            <span class="my_answer">参考答案：{{currentTest.correct_answer}}</span>-->
                    <!--            <span class="reference_answer">我的答案：{{currentTest.answer}}</span>-->
                    <!--          </div>-->
                    <!--          <div class="knowledge_box">-->
                    <!--            <span class="knowledge">考察知识点：</span>-->
                    <!--            <span class="knowledge-text">{{currentTest.knowledge}}</span>-->
                    <!--          </div>-->
                    <!--          <div class="accuracy_box">-->
                    <!--            <span class="accuracy">全网正确率：</span>-->
                    <!--            <span class="accuracy-text">{{currentTest.accuracy}}</span>-->
                    <!--            <van-button round class="video-analysis" icon="play-circle-o" type="info" size="mini" @click="onShowVideo">{{showVideo == true ? '隐藏视频' : '视频讲解'
                    }}</van-button>-->
                    <!--          </div>-->
                    <!--        </div>-->
                    <!--        <div class="comRadio_video_analysis" v-show="showVideo">-->
                    <!--          <van-cell center title="讲师视频讲解">-->
                    <!--          </van-cell>-->
                    <!--          <div class="video_box" ref="attrRef">-->
                    <!--            <video-->
                    <!--                id="myVideo"-->
                    <!--                class="video-js"-->
                    <!--                controls -->
                    <!--                preload="auto"-->
                    <!--                :poster="currentTest.videoAnalysisPoster"-->
                    <!--                :style="contentStyleObj"-->
                    <!--            >-->
                    <!--                <source :src="currentTest.videoAnalysis" type="video/mp4">-->
                    <!--            </video>-->
                    <!--          </div>-->
                    <!--        </div>-->
                    <!--        <div class="comRadio_analysis" >-->
                    <!--          <van-cell center use-label-slot title="解析">-->
                    <!--            <template #right-icon>-->
                    <!-- <van-button round center type="warning" size="mini" icon="question-o" @click="onShowShare">我要提问</van-button> -->
                    <!--            </template>-->
                    <!--          </van-cell>-->
                    <!--          <van-tag color="#f6f7f9" text-color="#646566" size="large">{{currentTest.analysis
                    }}</van-tag>-->
                    <!--        </div>-->
                </van-popup>
            </div>
        </div>
        <van-popup class="showTest1" v-model:show="showTestView" closeable close-icon-position="top-left" close-icon="arrow-left" :style="{ height: '100%',width:'100%' } " >
            <van-cell center title="试题详情" style="position: fixed;top: 0;text-align: center;z-index: 1;"></van-cell>
            <!-- 试题 -->
            <div class="result_testContent">
                <viewResolution :testPaperId="testPaperAnswerId" :url="url" :classType="type"></viewResolution>
            </div>
        </van-popup>
        <!--    <div v-model:show="showTestView">-->
        <!--      <viewResolution :testPaperId="testPaperAnswerId"></viewResolution>-->
        <!--    </div>-->
        <van-goods-action>
<!--            <van-goods-action-button color="#00468d" type="danger" text="继续做题" @click="continueQuestion" />-->
            <van-goods-action-button color="#3996f5" type="warning" text="查看解析" @click="handelAnalysis"/>
            <van-goods-action-button color="#1989fa" type="default" text="重新做题" disabled v-if="examType == 1" />
            <van-goods-action-button color="#1989fa" type="default" text="重新做题" @click="handelRedo" v-else />
        </van-goods-action>
    </div>
</template>

<script>
    // 导航栏
    import comnavBar from '@/components/com_navBar'
    // 单选题
    import comRadio from '@/components/com_radio'
    // 多选题
    import comCheckbox from '@/components/com_checkbox'
    // 配伍选择题-单选
    import comDataRadio from '@/components/com_data_radio'
    // 配伍选择题-多选
    import comDataCheckbox from '@/components/com_data_checkbox'
    // 判断题
    import comJudge from '@/components/com_judge'
    import viewResolution from '@/components/viewResolution'
    // 简答题
    import comshortAnswer from '@/components/com_shortAnswer'
    import {getQuestionExamResult, testPaperPracticeQuestionPost, getQuestionExamResultClass} from "@/request/api";
    import {Toast} from "vant";

    export default {
        components: {
            comRadio,
            comCheckbox,
            comDataRadio,
            comDataCheckbox,
            comJudge,
            comshortAnswer,
            comnavBar,
            viewResolution
        },
        props: ['testPaperAnswerId', 'testPaperId', 'type', 'title', 'url','commodityId','examType'],
        data() {
            return {
                // 考试情况
                testInfo: {},
                // 当前试题索引
                currentIndex: 0,
                // 当前试题
                currentTest: {},
                currentRate: 0,
                showTest: false,
                showVideo: true,
                contentStyleObj: {
                    height: '',
                    width: ''
                },
                showTestView: false
            }
        },
        mounted() {
            this.contentStyleObj.height = this.$refs.attrRef.offsetWidth * 9 / 16 + 'px ' + '!important';
            this.contentStyleObj.width = window.getComputedStyle(this.$refs.attrRef).width + ' ' + '!important';
        },

        computed: {
            text() {
                return this.currentRate.toFixed(1) + '%';
                //在这里返回的是环形进度条中间的文字加上百分比
            },
        },
        watch: {},
        created() {
            console.log('题库专题',this.url);
            // 获取考试情况
            this.getData()
        },
        methods: {

            onShowVideo() {
                this.showVideo = !this.showVideo
            },
            handelAnalysis() {
                // console.log(123123);
                this.showTestView = true;
                // console.log(this.showTestView);
                // this.$router.push({
                //   name: 'viewResolution',
                //   query: {id:this.testPaperAnswerId}
                // })
            },
            handelRedo() {
                if((this.examType == 1 || this.examType === 0 ) && this.url != undefined && this.url != 'QuestionRecord') {
                    this.$router.push(this.url)
                }
              this.$emit('againQuestion')
            },
            // 切换试题
            updateCurrentTestFun(val) {
                this.currentIndex = val
                this.currentTest = this.testInfo.testList[this.currentIndex]
                this.$store.state.currentIndex = this.currentIndex; //设置选项使用
                this.showTest = true
            },
            // 获取考试情况
            getData() {
                let that = this;
                if(this.type == 3) {
                    getQuestionExamResultClass({id: that.testPaperAnswerId})
                        .then(res => {
                            if (res.data.code == 1) {
                                // console.log(res.data);
                                that.testInfo = res.data.data.info;
                                that.currentTest = that.testInfo.testList[that.currentIndex]
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            Toast.fail('操作错误');
                        })
                } else {
                    getQuestionExamResult({id: that.testPaperAnswerId})
                        .then(res => {
                            if (res.data.code == 1) {
                                // console.log(res.data);
                                that.testInfo = res.data.data.info;
                                that.currentTest = that.testInfo.testList[that.currentIndex]
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            Toast.fail('操作错误');
                        })
                }

            },
            // 返回
            onClickLeft() {
                console.log(this.url);



                if(this.url == 'QuestionRecord') {
                    this.$emit('againQuestion')
                    return false;
                } else if((this.examType == 1 || this.examType === 0 )) {
                    this.$router.push(this.url)
                    return false;
                } else {
                    if(this.type == 3) {
                        //随堂练习
                        this.$router.push({
                            name: 'CoursePractice',
                            params: {
                                id: this.commodityId
                            },
                            query: {
                                id: this.commodityId
                            }
                        })
                    } else {
                        let url = '';
                        let id = this.testInfo.t_id;
                        if (id == 1) {
                            //历年真题
                            url = 'QuestionList'
                        } else if (id == 2) {
                            //模考试卷
                            url = 'QuestionList';
                        } else if (id == 3) {
                            //海量题库
                            url = 'QuestionListK';
                        } else if (id == 4) {
                            //每日一练
                            url = 'QuestionDailyPracticeList';
                        } else if (id == 7) {
                            //名师好题
                            url = 'QuestionListK';
                        } else if (id == 8) {
                            //考前冲刺
                            url = 'QuestionList';
                        } else if (id == 9) {
                            //通关白宝卷
                            url = '';
                        } else {
                            //其他
                            Toast('暂未开放');
                            return false;
                        }
                        this.$router.push({
                            name: url,
                            params: {
                                id: this.testInfo.t_id,
                                title: this.testInfo.cate_name
                            },
                            query: {
                                id: this.testInfo.t_id,
                                title: this.testInfo.cate_name
                            }
                        })
                    }
                }
            },
            result() {
                console.log(123123);
            }
        }
    }
</script>

<style scoped>
    .result {
        height: 100%;
        overflow-y: scroll !important;
    }

    .result_content {
        height: 100%;
        overflow-y: scroll !important;
    }
    .result_content .comRadio_top {
        height: 3rem !important;
        /* border-bottom: 0.1rem solid #f7f7f7; */
        position: fixed;
        left: 0rem;
        padding-left: 2rem;
        padding-right: 4rem;
        z-index: 99;
    }
    .result_content .comRadio_top .van-cell__title{
        height: 2.2rem;
        line-height: 2.2rem;
        text-align: left;
        border-left: 0.5rem solid #1989fa;
        padding-left: 1rem;
        font-weight: bold;
        font-size: 2.2rem;
    }

    .result_title {
        /* width: 100%; */
        height: 6rem;
        padding: 2rem 2rem 1rem 2rem;
        margin-top: 4rem;
        background-size: 100% 100%;
        line-height: 6rem;
        color: #323233;
        font-size: 2.4rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .result_mask {
        width: auto;
        margin: 0rem 1rem 1rem 1rem;
        background: #ffffff;
        border-radius: 1rem;
        box-shadow: 0 0 1rem gainsboro;
    }

    .result_img {
        /* margin-top: 5vw; */
        /* width: 55vw;
        height: 38vw;
        margin-left: 17.5vw; */
    }

    .correct {
        width: 18rem;
        height: 18rem;
    }

    .correct_text {
        text-align: center;
        font-size: 1.8rem;
        padding-bottom: 1rem;
    }

    .result_img_success {
        background-size: 100% 100%;
        text-align: center;
        padding: 2rem 2rem 1rem 2rem;
    }

    .result_img_fail {
        background-size: 100% 100%;
        text-align: center;
        padding: 2rem 2rem 1rem 2rem;
    }

    .result_score_success {
        font-size: 6rem;
        font-weight: bold;
        color: #e54435;
        height: 6rem;
        line-height: 6rem;
    }

    .result_score_fail {
        font-size: 6rem;
        font-weight: bold;
        color: #878787;
        height: 6rem;
        line-height: 6rem;
    }

    .result_threeDiv {
        width: 100%;
        height: 8rem;
        background: #ffff;
        border-top: 0.2rem solid #eeeeee;
        margin-top: 0.5rem;
        border-radius: 0 0 1rem 1rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result_threeDiv > div {
        width: 25%;
        text-align: center;
    }

    .result_threeDiv > div > div:nth-child(1) {
        font-weight: bold;
        height: 3rem;
        line-height: 3rem;
        font-size: 2rem;
        color: #323233;
    }

    .result_threeDiv > div > div:nth-child(2) {
        color: #696969;
        margin-top: 0.4rem;
        font-size: 1.8rem;
    }

    .result_threeDiv > div:nth-child(1) {
        border-right: 0.1rem solid #eeeeee;
    }

    .result_threeDiv > div:nth-child(2) {
        border-right: 0.1rem solid #eeeeee;
    }

    .result_threeDiv > div:nth-child(3) {
        border-right: 0.1rem solid #eeeeee;
    }

    .result_test {
        /* width: 95%; */
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        /* border-bottom: 0.1rem solid #eeeeee; */
        padding-bottom: 8rem;
    }

    .result_test .van-cell {
        margin-left: 0.5rem;
    }

    .result_test .van-cell::after {
        border-width: 0 !important;
    }

    .result_test .van-cell .tuli {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
    }

    .result_test .van-cell .tuli .van-tag {
        width: 1.5rem;
        height: 1rem;
        border-radius: 0.3rem;
        margin: 0.5rem;
    }

    .result_test .result_test_success,
    .result_test .result_test_fail,
    .result_test .result_test_none,
    .result_test .result_test_part {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
        border-radius: 50%;
        margin: 0.6rem;
        font-size: 1.6rem;
    }

    .result_test_none {
        background: #ffffff;
        color: #969799;
        border: 0.1rem solid #a2a3a5;
        text-align: center;
    }

    .result_test_success {
        background: #07c160;
        color: #ffffff;
        text-align: center;
        border: 0.1rem solid #ffffff;
    }

    .result_test_fail {
        background: #ee0a24;
        color: #ffffff;
        text-align: center;
        border: 0.1rem solid #ffffff;
    }

    .result_test_part {
        background: #ff976a;
        color: #ffffff;
        text-align: center;
        border: 0.1rem solid #ffffff;
    }

    .result_testContent {
        width: 100%;
        /* width: 95%; */
        /* margin: 1rem; */
        padding-top: 6rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.1rem solid #eeeeee;
    }

    .result_test_xx {
        margin-right: 1rem;
        overflow-y: scroll;
        padding-top: 6rem;
    }

    .result_test_tm {
        text-align: left;
        /* font-weight: bold; */
        font-size: 2rem;
        line-height: 3rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
    }

    .result_testAnswer {
        margin-top: 1rem;
        width: 100%;
        margin-left: 1rem;
        text-align: left;
        margin-bottom: 6rem;
    }

    .result_jx > div {
        margin-top: 3vw;
    }

    .result_jx > div:nth-child(1) {
        width: 12vw;
        height: 5vw;
        background: #cccccc;
        color: #ffffff;
        display: inline-block;
        font-size: 3.5vw;
        text-align: center;
        border-radius: 2px;
    }

    .van-goods-action {
        height: 6rem;
        padding-bottom: 0.1rem;
    }
</style>
